import {Decoration, DecorationSet} from "@codemirror/view"
import {StateField, StateEffect} from "@codemirror/state"
import {EditorView} from "@codemirror/view"

export const generatedEffect = StateEffect.define<{from: number, to: number}>({
  map: ({from, to}, change) => ({from: change.mapPos(from), to: change.mapPos(to)})
})

export const generatedMark = Decoration.mark({class: "cm-generated"})

export const generatedField = StateField.define<DecorationSet>({
  create: () => Decoration.none,
  update(generated, tr) {
    generated = generated.map(tr.changes)
    for (let e of tr.effects) if (e.is(generatedEffect)) {
      generated = generated.update({
        add: [generatedMark.range(e.value.from, e.value.to)]
      })
    }
    return generated  
  },
  provide: f => EditorView.decorations.from(f)
})

export const generatedTheme = EditorView.baseTheme({
  ".cm-generated": {
    textDecoration: "underline 1px dotted gray",
    textUnderlineOffset: "2px",
  }
})
