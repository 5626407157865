import { getAccessToken } from "../auth/MsalHelper";

export function MakeRequest(msal, state, dispatch, setStatus, setError, setResponseState) {

    function SendQuery(sock, auth) {
        const request = {
            prompt: state.context.replace(/💥/g,'').replace(/🛑/g,''),
            ...state.model_params
        }

        let msg = {
            endpoint: state.endpoint,
            auth: auth,
            custom_headers: state.custom_headers,
            payload: request
        }

        if (state.proxy === "API" && state.ephemeral.token) {
            msg['token'] = state.ephemeral.token;
        }

        setResponseState({headers: [], dt_start_request: new Date(), dt_first_token: null, dt_last_token:null, token_timestamps: []})

        console.log("Sending request", msg);
        sock.send(JSON.stringify(msg));
    }


    function ReceiveHandler(e) {
        //if (this.state.dt_first_token === null) { this.setState(prevState => ({dt_first_token: new Date()}))}

        const data = JSON.parse(e.data);

        if (data['type'] === 'status') { setStatus(data.details); }
        if (data['type'] === 'error') { setError(data.details); }
        if (data['type'] === 'headers') { console.log('Response headers', data.payload); setResponseState(old => ({...old, headers: data.payload})) }

        if (data.type === 'sse') {
            if (data.payload.error) {
                setError(data.payload.error.message);
            }
            else {
                if (data.payload.choices.length > 0) { 
                    var fragment = "";
                    if (data.payload.choices[0].finish_reason === "stop") { fragment = '🛑'; }
                    else { fragment = data.payload.choices[0].text; }
                    dispatch({type:'appendContext', value: fragment, logprobs: data.payload.choices[0].logprobs})
                }
                setResponseState(prevState => ({...prevState, token_timestamps: [...prevState.token_timestamps, new Date()]}));
            }
        }

        if (data.type === 'end') {
            setResponseState(prevState => ({...prevState, dt_last_token: new Date()}));
        }

    }


    dispatch({type:'appendContext', value:'💥'});
        
    var proxy_url = null;
    if (state.proxy === "API") { proxy_url = process.env.REACT_APP_API_ENDPOINT || ("wss://" + window.location.host + "/ws/") }
    else if (state.proxy === "Papyrus") { proxy_url = process.env.REACT_APP_PAPYRUS_ENDPOINT || ("wss://" + window.location.host + "/papyrus/") }

    getAccessToken(msal)
    .then((response) => {
        let socket = new WebSocket(proxy_url);
        socket.onopen = () => SendQuery(socket, response.accessToken );
        socket.onmessage = (e) => ReceiveHandler(e);
    })
}



