import {Tooltip, showTooltip, Decoration, DecorationSet} from "@codemirror/view"
import {StateField, StateEffect} from "@codemirror/state"
import {EditorView} from "@codemirror/view"

function SortLogprobs(logprobs): [string, number][] {
  let pairs = [];
  if (logprobs && logprobs.top_logprobs && logprobs.top_logprobs.length > 0) {
    for (let token of Object.keys(logprobs.top_logprobs[0])) {
      pairs.push([token, logprobs.top_logprobs[0][token]]);
    }
  }
  pairs.sort((a, b) => b[1] - a[1]);
  return pairs;
}

function ExplicitToken(token) {
  token = token.replace(/ /g, "⎵");
  token = token.replace(/\n/g, "⏎");
  return token;
}

export function GetLogprobsTable(logprobs) {
  let lp = "";
  for (let [token, prob] of SortLogprobs(logprobs)) {
    let highest = token === logprobs.tokens[0] ? "highest" : "";
    lp += `<div class=${highest}>` + ExplicitToken(token) + "</div>";
    lp += `<div class=${highest}>` + prob + "</div>";
  }
  return lp;
}


// Effects are applied when the generated token is appended
export const logprobsEffect = StateEffect.define<{from: number, to: number, logprobs: string}>({
  map: ({from, to, logprobs}, change) => ({from: change.mapPos(from), to: change.mapPos(to), logprobs: logprobs})
})

// logProbsField will translate the effect into a mark, basically applying a CSS class and passing the logprobs table as an attribute
export const logprobsMark = (details) => Decoration.mark({class: "cm-logprobs", attributes: {logprobs: details}})

// The logprobsField is a StateField that stores the logprobs for each generated token in the document
export const logprobsField = StateField.define<DecorationSet>({
  create: () => Decoration.none,
  update(logprobs, tr) {
    logprobs = logprobs.map(tr.changes)
    for (let e of tr.effects) if (e.is(logprobsEffect)) {
      logprobs = logprobs.update({
        add: [logprobsMark(e.value.logprobs).range(e.value.from, e.value.to)]
      })
    }
    return logprobs  
  },
  provide: f => EditorView.decorations.from(f)
})

export const logprobsFieldTooltip = StateField.define<Tooltip[]>({
  create: () => [],

  update(tooltips, tr) {
    // Clear all tooltips by default
    tooltips = []

    // Add a tooltip from logprobsField only if the cursor is on a generated token
    tr.state.field(logprobsField).between(
      tr.state.selection.main.head,
      tr.state.selection.main.head,
      (from, to, value) => { 
        if (!value || value.attrs.logprobs.length === 0) return;
        tooltips = [{
          pos: from,
          above: true,
          strictSide: true,
          arrow: true,
          create: () => {
            let dom = document.createElement("div")
            dom.className = "cm-tooltip-logprobs"
            dom.innerHTML = value.attrs.logprobs
            return {dom}
          }
        }]
      }
    )

    return tooltips
  },
  provide: f => showTooltip.computeN([f], state => state.field(f))
})


export const logprobsTheme = EditorView.baseTheme({
  ".cm-tooltip.cm-tooltip-logprobs": {
    backgroundColor: "#66b",
    color: "white",
    border: "none",
    padding: "2px 7px",
    borderRadius: "4px",
    fontSize: "x-small",
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridColumnGap: "5px",
    "& .cm-tooltip-arrow:before": {
      borderTopColor: "#66b"
    },
    "& .cm-tooltip-arrow:after": {
      borderTopColor: "transparent"
    },
    "& .highest": {
      fontWeight: "bold",
    }
  },
})
