export default function About() {
    return (<>
        <div className="about">
            Maintained by <a href="mailto:aoakley@microsoft.com">Andy Oakley</a>
            <br />
            <ul>
                <li>
                    <a href="https://eng.ms/docs/experiences-devices/webxt/search-and-distribution/core-search/codex-playground/codex-playground/">Docs</a>
                </li>
                <li>
                    <a href="https://outlook.office.com/newsletters/pages/1e12fc5d4bec0d4abc1ae05b11438a6923/read">Newsletter</a>
                </li>
            </ul>
        </div>

        <style jsx="true">{`
            div.about ul { margin: 0}
            div.about ul li { list-style: none; display: inline; }
            div.about ul li:not(:last-child):after { content: "  \\00b7  "; }
        `}</style>

    </>)
}