import React from "react";
import './Help.css'
import { useMsal } from "@azure/msal-react";
import RefreshRoles from "./auth/RefreshRoles";

function Help() {
    const {instance, accounts} = useMsal();

    return (
        <div className="help">
            <p>
                You do not have access to any models at this time. Authorization is controlled by security group membership. 
            </p>
            <p>
                Please refer to <a href="https://aka.ms/codexmodelaccess">aka.ms/codexmodelaccess</a> for access information.
                You will need to join a security group aligned with your team to gain access.
            </p>
            <p>
                Once added you may need to <RefreshRoles /> and <a href="/">reload the page</a>.
            </p>
            <p>
                If you believe you should have access and have followed the access instructions, please contact <a href="mailto:aoakley@microsoft.com">Andy Oakley</a>.
            </p>

            {accounts.length > 0 && <div style={{fontSize: "x-small"}}>
                Debug info
                <ul>
                    <li>{accounts[0].username}</li>
                    <li>{accounts[0].name}</li>
                    <li>{accounts[0].homeAccountId}</li>
                    <li>{accounts[0].idTokenClaims.roles?.map((role, i) => <span key={i}>{role},</span>)}</li>
                </ul>
            </div>}

        </div>
    )

}

export default Help;