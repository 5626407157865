import { useState, useEffect } from 'react'
import { useMsal } from "@azure/msal-react";
import './EndpointSelector.css'
import { usePlaygroundState } from '../PlaygroundProvider';

function EndpointSelector(props) {
    const { accounts } = useMsal();
    const [ state, dispatch ] = usePlaygroundState();

    const [ endpoints, setEndpoints ] = useState([]);
    const [ advanced, setAdvanced ] = useState(false);

    const models = [
            {name: "GPT-4o", value: "gpt4o"},
            {name: "GPT-4o mini", value: "gpt4omini"},
            {name: "GPT-4 Turbo", value: "gpt4turbo"},
       ];

    useEffect(() => {
        const userroles = accounts[0].idTokenClaims.roles ?? [];

        const helpers = process.env.REACT_APP_HELPERS_PREFIX || (window.location.protocol + "//" + window.location.host + "/helpers/");
        fetch(helpers + "endpoints")
        .then(q => q.json())
        .then(config_endpoints => {
            var filtered = new Set();
            for (const endpoint of config_endpoints) {
                for (const userrole of userroles) {
                    if (endpoint.roles.includes(userrole)) {
                        filtered.add(endpoint);
                    }
                }
            }

            const l = Array.from(filtered.values());
            setEndpoints(l);
       }
       )
    // ignore warning about props dependency 
    // eslint-disable-next-line 
    }, [accounts])
    
    if (!state.custom_headers) return;

    const matchedEndpoints = endpoints.filter(ep => ep.proxy === props.proxy && ep.url === props.endpoint);
    const matchedEndpoint = matchedEndpoints.length > 0 ? matchedEndpoints[0] : null;

    const userroles = accounts[0].idTokenClaims.roles ?? [];

    // TOOD Move this to Papyrus
    const all_aml_endpoints = {
        "gpt4": ["https://prom-gpt4-eval.westus2.inference.ml.azure.com/v1/engines/davinci/completions"],
        "gpt4turbo": ["https://prom-gpt4-turbo-nexsing-batch.eastus.inference.ml.azure.com/v1/engines/davinci/completions"],
        "deucalionv1": ["https://prom-sahara-deucalionv1-eval.swedencentral.inference.ml.azure.com/v1/engines/davinci/completions"]
    };
    const model_class = state.endpoint;
    const aml_endpoints = Object.keys(all_aml_endpoints).includes(model_class) ? all_aml_endpoints[model_class] : [];

    return (
        <>
            <div className="modelchoice">

                {models.length > 0 && <>
                    {models.map(m => 
                        <button 
                            className={state.proxy === "Papyrus" && state.endpoint === m.value ? "selected" : undefined}
                            disabled={!userroles.includes(m.value)}
                            key={m.value}
                            onClick={() => {
                                dispatch({type:'set', key:'proxy', value:'Papyrus'})
                                dispatch({type:'set', key:'endpoint', value:m.value})
                                dispatch({type: 'setCustomHeader', key: 'amlendpoint', value: ""})
                            }}>
                            {m.name}
                            </button>
                    )}
                </>}

                <button
                    className={state.proxy === "API" ? "selected" : undefined}
                    onClick={() => { 
                        dispatch({type:'set', key:'proxy', value:'API'}) 
                        dispatch({type:'set', key:'endpoint', value: ""})
                    }}
                    >Direct</button>

            </div>
            <div>
                <div onClick={() => setAdvanced(old => !old)}>
                    {advanced && <>&#x25bc;</> || <>&#x25b6;</>} Advanced
                </div>
                <datalist id="endpoints">
                    {aml_endpoints.map(e => <option key={e} value={e}>{e}</option>)}
                </datalist>
                <div style={{display: advanced ? 'block' : 'none'}}>
                    Custom AML endpoint
                    <input list="endpoints" style={{width: "100%"}}
                        value={Object.keys(state.custom_headers).includes("amlendpoint") && state.custom_headers["amlendpoint"] || ""}
                        onChange={e => { dispatch({type: "setCustomHeader", key: "amlendpoint", value: e.target.value}) }}
                    ></input>
                </div>

                <div
                    style={{display: (state.proxy === "Papyrus" && state.endpoint === "custom") ? 'block' : 'none'}}
                    >
                        custom
                    </div>

                <div 
                    style={{display: (state.proxy === "API") ? 'block' : 'none'}}
                    >
                        <input placeholder="Token" value={state.ephemeral.token} onChange={e => dispatch({type:'setEphemeral', key:'token', value:e.target.value})} />
                        <input placeholder="Endpoint" value={state.endpoint} onChange={e => dispatch({type:'set', key:'endpoint', value:e.target.value})} />
                    </div>

            <style jsx="true">{`
                .modelchoice { 
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                    grid-gap: 0.5em;
                }

            `}</style>

{/* 
                <button 
                    className={state.proxy === "Papyrus" && state.endpoint === "dv3" && "selected"}
                    onClick={() => {
                        dispatch({type:'set', key:'proxy', value:'Papyrus'})
                        dispatch({type:'set', key:'endpoint', value:'dv3'})
                    }}>
                    DV3<br />(GPT4-like)
                </button>
                <button 
                    className={state.proxy === "Papyrus" && state.endpoint === "chatgpt" && "selected"}
                    onClick={() => {
                        dispatch({type:'set', key:'proxy', value:'Papyrus'})
                        dispatch({type:'set', key:'endpoint', value:'chatgpt'})
                    }}>
                    Sahara<br />(GPT3.5-like)
                </button>
                <button className={isCustom && "selected"}
                    disabled={true}
                    >
                    Custom<br />API
                </button>
            </div>


            <div onClick={() => setAdvanced(old => !old)}>
                {advanced && <>&#x25bc;</> || <>&#x25b6;</>} Advanced
            </div>

            {advanced &&
            <div>
        {endpoints.length > 0 &&
        <>
        <select value={matchedEndpoint?.proxy} onChange={e => { 
//            setProxy(e.target.value); 
            props.setProxy(e.target.value); 
//            setEndpoint(endpoints.filter(ep => ep.proxy===e.target.value)[0]);

            //default to first
            props.setEndpoint(endpoints.filter(ep => ep.proxy===e.target.value)[0].url);
            }}>
            {[...new Set(endpoints.map(e => e.proxy))].map(e => <option key={e} value={e}>{e}</option>)}
        </select>

        <select value={matchedEndpoint?.url} onChange={e => { 
//            setEndpoint(endpoints.filter(ep => ep.url === e.target.value)[0]); 
            props.setEndpoint(e.target.value);  
            }}>
            {endpoints.filter(e => e.proxy === props.proxy).map(e => <option key={e.url} value={e.url}>{e.short}</option>)}
        </select>

        {matchedEndpoint && matchedEndpoint.amlendpoints && <datalist id="amlendpoint">
            {matchedEndpoint.amlendpoints.map(d => <option key={d}>{d}</option>)}
            </datalist>}
        </>
        }
    */}
        </div>


        </>
    );



}

export default EndpointSelector;