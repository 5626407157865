import { useMsal } from "@azure/msal-react";

export default function RefreshRoles() {
    const { instance, accounts } = useMsal();

    function ForceRefresh() {
        instance.acquireTokenRedirect({
            scopes: ['2559036f-319d-42ff-adbd-e8ec5d35214f/completion'],
            account: accounts[0],
            forceRefresh: true
        })
    }

    return (
        <button onClick={ForceRefresh}>Refresh roles</button>
    )
}
