export const msalConfig = {
    auth: {
        clientId: '2559036f-319d-42ff-adbd-e8ec5d35214f',
        authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
        redirectUri: window.location.protocol + "//" + window.location.host 
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    }
};

export const loginRequest = {
    scopes: [
        "User.Read",
    ]
}

export const logoutRequest = {
    postLogoutRedirectUri: msalConfig.auth.redirectUri
}