import { useState, useEffect } from "react";
import { usePlaygroundState } from "../PlaygroundProvider";

export default function TokenCounter() {
    const [state, dispatch] = usePlaygroundState();

    const [tokenCount, setTokenCount] = useState();

    function count_tokens()  {
        fetch(state.ephemeral.helpers + "tokenizer", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'text': state.context})
        })
        .then(r => r.json())
        .then(r => setTokenCount(r.tokens));
    }

    useEffect(() => {
        // reset count if context changes
        setTokenCount(null);
    }, [state.context])

    return (<>
        {tokenCount && <span>{tokenCount} tokens</span>}
        {!tokenCount && <button onClick={count_tokens}>{state.context.length} chars</button>}
    </>);
}