import { usePlaygroundState } from "../PlaygroundProvider"
import unescapeJs from 'unescape-js';

function hasEscapes(s) {
    const escapes = ["\\n", "\\u"];
    
    for (const escape of escapes) {
        if (s.includes(escape)) return true;
    }

    return false;
}

export default function Unescaper() {
    const [state, dispatch] = usePlaygroundState();

    if (!hasEscapes(state.context)) return;

    return (
        <input type="button" 
            value="Unescape" 
            style={{backgroundColor: "red" }}
            onClick={() => dispatch({type:'setContext', value: unescapeJs(state.context)})}></input>
    )

}