import { useEffect, useState } from "react";
import { usePlaygroundState } from "../PlaygroundProvider";

const paramset = [
    {
        'title': 'Temperature',
        'name': 'temperature',
        'min': 0,
        'max': 1,
        'type': 'float'
    },

    {
        'title': 'Max tokens',
        'name': 'max_tokens',
        'min': 1,
        'max': 2048,
        'type': 'int'
    },

    {
        'title': 'Top P',
        'name': 'top_p',
        'min': 0,
        'max': 1,
        'type': 'float'
    },

    {
        'title': 'Freq penalty',
        'name': 'frequency_penalty',
        'min': -2.0,
        'max': 2.0,
        'type': 'float'
    },

    {
        'title': 'Presence penalty',
        'name': 'presence_penalty',
        'min': -2.0,
        'max': 2.0,
        'type': 'float'
    },

    {
        "title": "Logprobs",
        "name": "logprobs",
        "type": "int"
    }
]

export function parseType(val, type) {
    if (type==='float') return parseFloat(val);
    if (type==='int') return parseInt(val)
    return val;
    
}

function ModelParam({title, name, type, min, max, value, onChange}) {
    const [raw, setRaw] = useState(String(value));

    // some of the logic here is a bit confusing
    // null -> null
    // "" -> null
    // 0 -> 0
    // else parse as a float or int, return null if that fails

    const safeParse = (val, type) => {
        if (val === null || val === undefined) return null;
        if (val.length === 0) return null;
        if (val === 0) return 0;

        let res = null;
        if (type === 'int') { res = parseInt(val); }
        if (type === 'float') { res = parseFloat(val); }

        if (res !== null && !isNaN(res)) return res;
        return null;
    }

    // sometimes value might change externally
    // and we want to catch up with it
    useEffect(() => {
        if (value === undefined) {
            setRaw("")
            onChange(name, null)
            return;
        }
        if (safeParse(value, type) !== safeParse(raw, type)) {
            setRaw(String(value));
        }
    }, [value]);


    let invalid = String(safeParse(value, type)) !== raw;;
    if (raw.length === 0) { invalid = false; }

    return (<>
        <div>{title}</div>
        <div>
            <div style={{display: "grid", gridTemplateColumns: "auto "+(invalid?"20px":"0")}}>
                <input type="text" 
                    value={raw}
                    onChange={e => { setRaw(e.target.value); onChange(name, safeParse(e.target.value, type)); }}>
                </input>        
                {invalid && "⚠️"}
            </div>
        </div>
    </>)
}

function ModelStopWords({words, onChange}) {
    // eslint-disable-next-line
    useEffect(() => { onChange('stop', (words && words.length > 0) ? words : null, null) }, [words]);

    return (<>
            <div>Stop words</div>
            <div style={{display: "grid"}}>
                {words && words.map((word, i) => <div key={i} style={{display:"grid", gridTemplateColumns: "auto auto" }}>
                    <input type="text" value={word} onChange={e => onChange('stop', [...words.filter(iword => iword !== word), e.target.value])}></input>
                    <button onClick={() => onChange('stop', words.filter(iword => iword !== word))}>X</button>
                </div>)}
                <button onClick={() => onChange('stop', words ? [...words, ""] : [""])}>Add</button>
            </div>
            </>
    )
}

export default function ModelParams() {
    const [state, dispatch] = usePlaygroundState();

    function onChange(n, v) {
        dispatch({type:'setModelParam', key: n, value: v})
    }

    return (<div style={{display: "grid", gridTemplateColumns: "40% auto", gap: "2px"}}>
        {paramset.map(param => 
            <ModelParam key={param.name} {...param} value={state.model_params[param.name]} onChange={onChange}></ModelParam>
        )}
        <ModelStopWords words={state.model_params.stop} onChange={onChange}></ModelStopWords>
    </div>)
}

