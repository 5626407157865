
export const defaultHeaders = {
    'azureml-model-deployment': '', 
    amlendpoint: '',
    'Openai-Internal-AllowedSpecialTokens': '<|im_start|>,<|im_sep|>,<|im_end|>',
    'Openai-Internal-AllowedOutputSpecialTokens': '<|im_start|>,<|im_sep|>,<|im_end|>',
    'Openai-Internal-HarmonyVersion': 'harmony_v3'
};

export const defaultModelParams = {
    temperature: 0,
    max_tokens: 512,
    top_p: 1.0,
    frequency_penalty: 0,
    presence_penalty: 0,
    logprobs: 5,
    stop: ["<|im_end|>"]
}