import React from "react";
import {Route, Routes} from 'react-router-dom'

import './App.css';

import CompletionPlayground from './CompletionPlayground/CompletionPlayground';
import Help from './Help'
import { SignInButton } from './auth/SignInButton';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './appInsights';

import {build, branch} from "./version";
import SignOutButton from "./auth/SignOutButton";


function App() {
  return (
    <div className="App">
      <header className="main-header">
        <img src="playground-256.png" width="75" height="75"></img>
        <div>
          Codex Playground
          <br />
          <AuthenticatedTemplate>
            <div className="build">
              <span title={branch}>{build}</span>
            </div>
          </AuthenticatedTemplate>
        </div>
        <div>
          <AuthenticatedTemplate> <SignOutButton></SignOutButton> </AuthenticatedTemplate>
          <UnauthenticatedTemplate> <SignInButton></SignInButton></UnauthenticatedTemplate>
        </div>
      </header>

      <AuthenticatedTemplate>
        <Routes>
          <Route exact path="/" element={<CompletionPlayground />}></Route>
          <Route path="/help" element={<Help />}></Route>
        </Routes>
      </AuthenticatedTemplate>      

    </div>
  );
}

export default withAITracking(reactPlugin, App);
