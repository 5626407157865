import { useMsal  } from "@azure/msal-react"
import RefreshRoles from "../auth/RefreshRoles"
import SignOutButton from "../auth/SignOutButton"
import { Link } from "react-router-dom"


export default function AccessInfo() {
    const { instance, accounts } = useMsal();
    const roles = accounts[0].idTokenClaims.roles ?? [];

    return (
        <>
         <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <label>{accounts[0].name} </label> 
            <RefreshRoles></RefreshRoles>
        </div>

        <div>
            {roles.length === 0 && 
            <div style={{flexDirection:'column'}}>
                <div>
                    <Link to="/help">Not preauthorized. See more...</Link>
                </div>
            </div>}
            
            {roles.length > 0 &&
            <div className="pillcontainer">
                {roles.join(", ")} 
            </div>
            }
        </div>

        <div>
        </div>
        </>
    )

}