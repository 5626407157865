import { useMsal } from "@azure/msal-react";
import { logoutRequest } from "./authConfig";

function signOutClickHandler(instance) {
    instance.logoutRedirect(logoutRequest);
  }

function SignOutButton() {
    const { instance } = useMsal();

    return (
        <button onClick={() => signOutClickHandler(instance)}>Sign out</button>
    )
}

export default SignOutButton;