import { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from '../auth/MsalHelper';

function PermalinkOfState({state}) {
    const DEFAULT_LABEL = "Copy permalink";

    const [ label, setLabel ] = useState(DEFAULT_LABEL);
    const { instance, accounts } = useMsal();

    const copyShareLink = () => {
        getAccessToken({instance, accounts})
        .then(token => {
            const url = process.env.REACT_APP_SAVESTATE_URL || (window.location.protocol + "//" + window.location.host + "/savestate");
            fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.accessToken}`
                },
                body: JSON.stringify({
                    'state': state, 
                    'owner': accounts[0].localAccountId,
                    'ownerName': accounts[0].name
                })
            })
            .then(q => q.json())
            .then(r => {
                const goUrl = window.location.protocol + "//" + window.location.host  + window.location.pathname + "?permalink=" + r.id;
                navigator.clipboard.writeText(goUrl);
                setLabel("Copied!");
                setTimeout(() => setLabel(DEFAULT_LABEL), 5*1000);
            })
        });
    }

    return (<button onClick={copyShareLink}>{label}</button>)
}

export default PermalinkOfState;