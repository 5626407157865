import { useContext, useReducer, createContext } from 'react';
import { HandleAppend } from './PlaygroundInput/PlaygroundInput';
import { defaultHeaders, defaultModelParams } from './modelDefaults';

const PlaygroundContext = createContext(null);
const PlaygroundDispatchContext = createContext(null);

export function PlaygroundProvider({ children }) {
    const [ state, dispatch ] = useReducer(playgroundReducer, initialState);

    return (
        <PlaygroundContext.Provider value={state}>
            <PlaygroundDispatchContext.Provider value={dispatch}>
                {children}
            </PlaygroundDispatchContext.Provider>
        </PlaygroundContext.Provider>
    )
}

export function usePlaygroundState() {
    return [
        useContext(PlaygroundContext),
        useContext(PlaygroundDispatchContext)
    ]
}

function playgroundReducer(prevState, action) {
    switch (action.type) {
        case 'set': {
            if (action.persist) {
                if (action.value) { localStorage.setItem(action.key, action.value); }
                else { localStorage.removeItem(action.key); }
            }
            return {
                ...prevState,
                [action.key]: action.value
            }
        }

        case 'mergeSavedState': {
            // We overwrite all state except for the ephemeral settings (like the editor view)
            const n= {
                ...initialState,
                ...action.value,
                ephemeral: prevState.ephemeral
            }
            return n;
        }

        case 'setContext': {
            return {
                ...prevState,
                context: action.value
            }
        }

        case 'appendContext': {
            // This is a bit clumsy, but we need to bridge between react state and CM state
            if (prevState.ephemeral.editorView) {
                HandleAppend(prevState.ephemeral.editorView, action.value, action.logprobs);
            }

            return {
                ...prevState,
                context: prevState.context + action.value
            }
        }

        case 'setModelParam': {
            return {
                ...prevState,
                model_params: { ...prevState.model_params, [action.key]: action.value }
            }
        }

        case 'setCustomHeader': {
            return {
                ...prevState,
                custom_headers: { ...prevState.custom_headers, [action.key]: action.value }
            }
        }

        case 'removeCustomHeader': {
            const newHeaders = { ...prevState.custom_headers };
            delete newHeaders[action.key];
            return {
                ...prevState,
                custom_headers: newHeaders
            }
        }

        case 'setEphemeral': {
            return {
                ...prevState,
                ephemeral: {
                    ...prevState.ephemeral,
                    [action.key]: action.value
                }
            }
        }

        case 'setEditorView': {
            return {
                ...prevState,
                ephemeral: {
                    ...prevState.ephemeral,
                    editorView: action.value
                }
            }
        }

        default: {
            throw Error("Unknown" + action.type)
        }
    }
}

export function exportState(state) {
    const { ephemeral, ...exportable } = state;
    return exportable;
}


const initialState = {
    endpoint: "gpt4o",
    proxy: "Papyrus",
    custom_headers: defaultHeaders,
    context: "",
    model_params: defaultModelParams,
    ephemeral: {
        helpers: process.env.REACT_APP_HELPERS_PREFIX || (window.location.protocol + "//" + window.location.host + "/helpers/"),
        editorView: null
    }
}