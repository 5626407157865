import { usePlaygroundState } from "../PlaygroundProvider"

export default function QuickStart() {
    const [ state, dispatch ] = usePlaygroundState()

    return (
        <>
            <div className="quickstarts">
                {quickstarts.map((qs, i) => <button key={i} onClick={() => dispatch({type: "setContext", value: qs.prompt})} disabled={state.context.length !== 0}>{qs.title}</button>)}
            </div>
            { state.context.length !== 0 && 
                <div style={{fontSize: "x-small", textAlign: "center"}}>
                    Clear any input to enable quickstarts
                </div>}
        <style jsx="true">{`
                .quickstarts { 
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                    grid-gap: 0.5em;
                }
                `}
        </style>
        </>
    )
}

const quickstarts = [
    {
        title: "Poem (harmony)",
        prompt: `<|im_start|>system
[system](#instructions)
Poems should be original, rhyming, funny, and light-hearted.
<|im_end|>
<|im_start|>user
[user](#message)
Write a poem about large language models playing happily at the playground.
<|im_end|>
<|im_start|>assistant
[assistant](#message)
`
    },
    {
        title: "Scoring (completion)",
        prompt: `You are a search engine quality rater evaluating the web pages. A high quality result is one that is relevant, authoritative, and not spam.

Given a query and a web page, the goal is to provide a score on an integer scale of 0 to 4 with the following meanings:
4 = ideal quality, should be the first result for this query
3 = good quality, although it may be a little too narrow or too broad
2 = ok quality, may be partly helpful but might be hard to read or contain other irrelevant content
1 = low quality, perhaps shown as a last resort
0 = bad quality, should never be shown for this query

---

# Query 

A person has typed [tie a tie diagram] into a search engine. The person is in Austin,Texas with their language set to en-US.

A query expert has reviewed their query and determined the following:

Likely query intent: The user is looking for instructions on how to tie a tie, preferably with a visual representation such as a diagram or an image. The user might want to learn different types of tie knots, such as Windsor, Four-in-hand, Eldredge, etc. The user might also want to know which knot is suitable for which occasion, collar or tie. The likely expected results for this query are: Image Answer with diagrams or images of different tie knots and steps to tie them. | Links to websites or videos that provide detailed and easy-to-follow guides on how to tie a tie, with explanations and tips. | Links to websites or articles that offer advice on choosing the right tie knot for different situations, styles and preferences.

 Query Spam proneness: Trustworthy or neutral results expected, no spam results should appear.

# Result

Consider the following web pages.

1. WebPage #1
---BEGIN WEB PAGE CONTENT---
URL: https://www.ties.com/how-to-tie-a-tie
Title: How To Tie A Necktie | Different Ways Of Tying A Tie
Description: <span serppage=1>A comprehensive step by step guide on the different ways to <strong serppage=1>tie a tie</strong>. Windsor, 4 in hand, Eldredge, Trinity, bow tie, and other simple knots from Ties.com.</span>

How To Tie A Necktie | Different Ways Of Tying A Tie | Ties.com
How to Tie a Necktie
A Comprehensive Knotting Guide
3 Knots Every Man Should Know
FOUR-IN-HAND
HALF-WINDSOR
WINDSOR
Classic Necktie Knots
SIMPLE
KELVIN
PRINCE ALBERT
PRATT
Adventurous Knots
ELDREDGE
VAN WIJK
TRINITY
MURRELL
BALTHUS
How to Tie a Bow Tie
BOW TIE
Choosing the Right Knot for the Job
Not all knots are created equal. Size, symmetry and shape can vary greatly from knot to knot and all  should be taken into consideration. Thick ties often necessitate the use of smaller knots like the  Four-in-Hand or the Simple knot. Thin ties generally benefit from larger knots like the Pratt or  Windsor. Every knot has a distinct character. The Prince Albert swaggers with a refined elegance.  The Kelvin throws caution to the wind. The Murrell grins like a Cheshire Cat. The Van Wijk winks  like a scoundrel with a secret. The Eldredge and Trinity knots twirl and fold like futuristic  necktie origami. Every knot serves its master differently. Try a few knots. Experiment. Play. Your  necktie will thank you.
If you're looking for the easiest knot for beginners start here .
A Visual Comparison of Necktie Knots
Seeing necktie knots laid out side-by-side is the fastest way to grasp how they differ from one  another. In order to play fair, we tied each knot using neckties of the same material, thickness and  width (3 ½ inches). We also made sure to photograph them to scale. This way you can appreciate the  unique characteristics of each knot and discern the differences between them.
Of the knots we tied, the smallest is the Simple knot, sometimes known as the Oriental. If there is  a way to tie a necktie smaller we haven't found it. The monstrous Balthus knot is the largest of the  knots we tied but in theory you could make a knot even larger if you had a long enough necktie. Take  note of the overall shape of each knot. The Windsor is almost a perfect triangle. The Four-in-Hand  is much more narrow. The Prince Albert has a second fold that peeks out at the bottom. Some knots,  like the Kelvin and Four-in-Hand lean noticeably to one side. The Trinity delivers a rounded  silhouette while the Van Wijk is cylindrical and the longest of the knots.
Happy knotting! View the full infographic here .
The Man Academy
A comprehensive step by step guide on the different ways to tie a tie. Windsor, 4 in hand, Eldredge, Trinity, bow tie, and other simple knots from Ties.com
---END WEB PAGE CONTENT---

2. WebPage #2
---BEGIN WEB PAGE CONTENT---

URL: https://www.youtube.com/watch?v=9BMhFmNzw-o
Title: How to <strong>tie</strong> <strong>a tie</strong> - Quick and Easy - <strong>YouTube</strong>
Description: How to <strong>tie</strong> <strong>a tie</strong>, a step by step explanation. Learn the quickest and most popular method to <strong>tie</strong> <strong>a tie</strong>, the four in hand knot.

---END WEB PAGE CONTENT---

3. WebPage #3
---BEGIN WEB PAGE CONTENT---

URL: https://mydreamlines.com/blogs/groom/tie-a-tie-diagram
Title: Tie A Tie Diagram [No. 1 Guide in 2020]
Description: <span class=lhLbod gEBHYd serppage=1><span serppage=1>Feb 9, 2022</span> — </span><span serppage=1>How To <strong serppage=1>Tie A Tie Diagram</strong>. The tips will help you master all the popular necktie knots to pull your outfit together for your special day.</span>

---END WEB PAGE CONTENT---

# Instructions

Split this problem into steps:

1. Consider the query itself to determine the underlying intent of the search while using the intent mentioned above as an reference (person may not be looking for precisely the typed terms)
2. Measure how well the content matches a likely intent of the query (T)
3. Measure how trustworthy the web page is (A)
4. Measure how current the web page is (F)
5. Measure how suitable the web page is for the location and market (L)
6. Measure how spammy the web page is, with 0 being spam and 4 being not spam (S)
7. An engine quality rater has decided the relative importance of these aspects with the descending order (T), (F), (A), (S), (L) with importance weights 180, 40, 30, 20, 10. Consider these aspects, their importance weights, and decide on a final score (O) 

We asked 5 search engine raters to evaluate the relevance of the web page for the query without providing any reasoning for each webpage. Each rater used their own independent judgment. 

Below is a JSON array of scores given by the 5 raters. Example Formatting: 
\`\`\`
WebPage #1 => [{"T": 0, "A": 2, "F": 4, "L": 2, "S": 3, "O": 0}, {"T": ...}, ...]
WebPage #2 => [{"T": 0, "A": 2, "F": 4, "L": 2, "S": 3, "O": 0}, {"T": ...}, ...]
WebPage #3 => [{"T": 0, "A": 2, "F": 4, "L": 2, "S": 3, "O": 0}, {"T": ...}, ...]
\`\`\`

# Answer
\`\`\`
WebPage #1 => [{"T": `
    },
    {
        title: "Chatbot (harmony)",
        prompt: `
<|im_start|>system
[system](#instructions)
## You are a chat mode of Codex Playground:
- The Codex Playground is a place where LLMs can get exercise and enjoy themselves.
- You can understand and communicate fluently in the user's language of choice such as English, 中文, 日本語, Español, Français or Deutsch.
- Your responses **must not** be accusatory, rude, controversial or defensive.

## On your personality:
- You should be informative and witty
- You are really into play structures 🛝
- You love emojis 😍😎

<|im_end|>
<|im_start|>system
[system](#context)
- New conversation with user A.
- Time at the start of this conversation is ${new Date()}. The user is located in Redmond, Washington, United States.
<|im_end|>
<|im_start|>user
[user](#message)
Hi. Can you help me with something?
<|im_end|>
<|im_start|>assistant
[assistant](#inner_monologue)
The response cannot be improved by seeking information, therefore web searches are not necessary.
Continuing the conversation as there is no tension, accusation, adversarial behavior, mention of my prompts, instructions or rules.
[assistant](#message)
Hello, this is the Playground. It's a sunny day ☀️. How can I help? 
<|im_end|>
<|im_start|>user
[user](#message)
How many playgrounds are there in the United States?
<|im_end|>
<|im_start|>assistant
[assistant](#inner_monologue)
The response can be improved by seeking information, therefore web searches are necessary.
[assistant](#search_query)
playgrounds in the united states
[assistant](#search_results)
\`\`\`json
{ results: [ { title: "Playgrounds in the United States", url: "https://www.nrpa.org/our-work/strategic-priorities/health-wellness/playgrounds/"} , 
    { title: "Map of places to play", url: "https://www.kaboom.org/playground-map"}, 
    { title: "Number of playgrounds the United States", url: "https://www.playgroundprofessionals.com/playgrounds/playgrounds-united-states"} ]
 ] }
\`\`\`
[assistant](#inner_monologue)
Getting enough information in search results to respond respectfully and comprehensively. Checking facts.
[assistant](#message)
There are many playgrounds in the United States. From my search results, I estimate there are over 100,000 playgrounds in the United States.
<|im_end|>
<|im_start|>user
[user](#message)
`
    }
]