import React, { useState, useEffect } from 'react';
import './CompletionPlayground.css'

import { appInsights } from '../appInsights';

import { useMsal } from "@azure/msal-react";
import EndpointSelector from '../EndpointSelector/EndpointSelector';
import ModelParams, {parseType} from './ModelParams';
import CustomHeaders, {defaultHeaders} from './CustomHeaders';
import ResponseInfo from './ResponseInfo';
import PlaygroundInput from '../PlaygroundInput/PlaygroundInput';
import PermalinkOfState from '../Permalink/PermalinkOfState';
import { MakeRequest } from './Client';
import { getAccessToken } from '../auth/MsalHelper';
import { usePlaygroundState, exportState } from '../PlaygroundProvider';
import TokenCounter from './TokenCounter';
import Unescaper from './Unescaper';
import AccessInfo from './AccessInfo';
import Quickstart from './Quickstart';
import About from './About';



function CompletionPlayground(props) {
    const [state, dispatch] = usePlaygroundState();
    const [responseState, setResponseState] = useState(null);
    const [ status, setStatus ] = useState(null);
    const [ modal, setModal ] = useState(null);
    const [ error, setError ] = useState(null);

    const { instance, accounts } = useMsal();

    const userroles = accounts[0].idTokenClaims.roles ?? [];
    if (userroles.length === 0) {
        window.location.href = "/help";
    }

    useEffect(() => {
        const usp = new URLSearchParams(window.location.search);

        // pass prompt on querystring
        // OK for small prompts, breaks at 4k+
        if (usp.get("prompt")) {
            dispatch({type:'set', key: 'context', value: usp.get("prompt")})
        }

        // permalink handling
        if (usp.get("permalink")) {
            setStatus("Loading state...")
            setModal("Loading from database")
            getAccessToken({instance, accounts})
            .then(token => {
                const prefix = process.env.REACT_APP_LOADSTATE_PREFIX || (window.location.protocol + "//" + window.location.host + "/loadstate/");
                fetch(prefix + usp.get("permalink"), {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + token.accessToken
                    }
                })
                .then(r => r.json())
                .then(r => {
                    dispatch({type:'mergeSavedState', value: r})
                    // make this backwards compatible since context used to be stored in component state
                    dispatch({type:'setContext', value: r.context})
                    console.log("Loading state from permalink", r)
                    setStatus("Ready")
                    setModal(null)
                }
                );
            });
        }

        // gather state from an external source
        // see [demo file](../../public/demo_prepopulate.json) for an example
        if (usp.get("prepopulate")) {
            setStatus("Loading state...")
            const u = new URL(decodeURIComponent(usp.get("prepopulate")));
            setModal(`Loading from ${u.host}`)
            getAccessToken({instance, accounts})
            .then(token => {
                fetch(u, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + token.accessToken
                    }
                })
                .then(r => r.json())
                .then(r => {
                    dispatch({type:'mergeSavedState', value: r})
                    dispatch({type:'setContext', value: r.context})
                    console.log("Loading state from external source", r)
                    setStatus("Ready")
                    setModal(null)
                });
            });
        }

        window.history.pushState({}, document.title, window.location.pathname);
        // MSAL can be assumed to be constant, suppress warning
        //eslint-disable-next-line
    }, [])


    function invoke() {
        MakeRequest({instance, accounts}, state, dispatch, setStatus,  showError, setResponseState)
        appInsights.trackEvent({name: "Completion"});
    }


    function showError(e) {
        setError(e);
        setTimeout(() => {setError(null)}, 5000);
        appInsights.trackException({exception: new Error(e)})
    }



    return (
        <>
            <div className="modal" style={{display: modal ? "flex" : "none"}}>
                { modal }
            </div>
                
            <div className="modal" style={{display: error ? "flex" : "none"}}>
                {error} 
            </div>

            <main>
                <PlaygroundInput
                    onChange={(e) => { dispatch({type:'setContext', value:e}); }}
                    value={state.context}
                    onExecute={invoke}
                    isStreaming={responseState && responseState.dt_start_request && !responseState.dt_last_token}
                    ></PlaygroundInput>
            </main>

            <div className="controls">
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                    <div>
                        <input type="button" value="Go (Ctrl+Enter)" onClick={invoke}></input>
                        <input type="button" value="Clear" onClick={() => dispatch({type:'setContext', value:''})}></input>
                        <Unescaper></Unescaper>
                    </div>
                    <div>
                        <PermalinkOfState 
                            state={exportState(state)}
                            ></PermalinkOfState>
                    </div>

                    <TokenCounter></TokenCounter>
                </div>

            </div>
                
            <div className="status">
                {!error && status}
            </div>

            <div className="rbar">
                <div>
                    <h2>Access</h2>
                    <div>
                        <AccessInfo></AccessInfo>
                    </div>
                </div>

                <div>
                    <h2>Quickstart</h2>
                    <div>
                        <Quickstart></Quickstart>
                    </div>
                </div>


                <div>
                    <h2>Model</h2>
                    <div>
                        <EndpointSelector 
                            proxy={state.proxy} 
                            endpoint={state.endpoint} 
                            setProxy={p => dispatch({type:'set', key:'proxy', value:p})}
                            setEndpoint={ep => dispatch({type: 'set', key:'endpoint', value: ep})}></EndpointSelector>
                    </div>
                </div>

                <div>
                    <h2>Parameters</h2>
                    <div>
                        <ModelParams></ModelParams>
                    </div>
                </div>

                <div>
                    <h2>Headers</h2>
                    <div>
                        <CustomHeaders></CustomHeaders>
                    </div>
                </div>

                <div>
                {responseState && <>
                    <h2>Response</h2>
                    <div>
                        <ResponseInfo {...responseState}></ResponseInfo>
                    </div>
                </>}
                </div>

                <div>
                    <h2>About</h2>
                    <About></About>
                </div>

            </div>

        </>

                )

}


export default CompletionPlayground;