import { MatchDecorator, ViewPlugin, Decoration } from '@codemirror/view';


//  matches <|im_start|> <|im_sep|> <|im_end|>
let specialDecorator = new MatchDecorator({
    // eslint-disable-next-line
  regexp: /(\<\|[^\|]*\|\>)/g,
  decoration: (m) => Decoration.mark({ class: 'special' }),
});

export const specialTokenHighlighter = ViewPlugin.define(
  (view) => ({
    decorations: specialDecorator.createDeco(view),
    update(u) {
      this.decorations = specialDecorator.updateDeco(u, this.decorations);
    },
  }),
  {
    decorations: (v) => v.decorations,
  }
);


// matches fences like ---START--- and ---END---
let fenceDecorator = new MatchDecorator({
  regexp: /^(---.*---)$/g,
  decoration: m => Decoration.mark({class: "fence"})
})

export const fenceHighlighter = ViewPlugin.define(
  (view) => ({
    decorations: fenceDecorator.createDeco(view),
    update(u) {
      this.decorations = fenceDecorator.updateDeco(u, this.decorations);
    },
  }),
  {
    decorations: (v) => v.decorations,
  }
);


// matches lines that start with a name: value pair
let fieldDecorator = new MatchDecorator({
  regexp: /^([^ ]+):/g,
  decoration: m => Decoration.mark({class: "field"})
})

export const fieldHighlighter = ViewPlugin.define(
  (view) => ({
    decorations: fieldDecorator.createDeco(view),
    update(u) {
      this.decorations = fieldDecorator.updateDeco(u, this.decorations);
    },
  }),
  {
    decorations: (v) => v.decorations,
  }
);


// matches turn by turn [assistant](#inner_monologue))
let personaDecorator = new MatchDecorator({
  regexp: /^(\[.*\]\(#.*\))$/g,
  decoration: m => Decoration.mark({class: "persona"})
})

export const personaHighlighter = ViewPlugin.define(
  (view) => ({
    decorations: personaDecorator.createDeco(view),
    update(u) {
      this.decorations = personaDecorator.updateDeco(u, this.decorations);
    },
  }),
  {
    decorations: (v) => v.decorations,
  }
);


// matches jinja2 style {{variable}}
let jinjaDecorator = new MatchDecorator({
  regexp: /\{\{.*?\}\}/g,
  decoration: m => Decoration.mark({class: "jinja"})
})

export const jinjaHighlighter = ViewPlugin.define(
  (view) => ({
    decorations: jinjaDecorator.createDeco(view),
    update(u) {
      this.decorations = jinjaDecorator.updateDeco(u, this.decorations);
    },
  }),
  {
    decorations: (v) => v.decorations,
  }
);
