import { set } from "lodash";
import { usePlaygroundState } from "../PlaygroundProvider";
import React, { useState } from "react";

export default function CustomHeaders({values, onChange}) {
    const [state, dispatch] = usePlaygroundState();

    const [ newName, setNewName ] = useState("");
    
    function onChange(n, v) {
        dispatch({type:'setCustomHeader', key:n, value: v})
    }

    return (
        <>
            <div className="cheader">
                {state.custom_headers && Object.keys(state.custom_headers).map(k => <React.Fragment key={k}>
                    <div><input value={k} readOnly></input></div>    
                    <div><input value={state.custom_headers[k]} list={k} onChange={v => onChange(k, v.target.value)}></input></div>
                    <div><button onClick={() => dispatch({type: "removeCustomHeader", key: k})}>X</button></div>
                </React.Fragment>)}

                <div><input placeholder="Add another" value={newName} onChange={e => setNewName(e.target.value)}></input></div>
                <div><button 
                    disabled={newName === null || newName.length === 0}
                    onClick={() => { dispatch({type:'setCustomHeader', key:newName, value: ""}); setNewName(""); }}
                    >Add</button></div>
            </div>
            <style jsx="true">{`
                .cheader { 
                    display: grid;
                    grid-template-columns: 1fr 1fr 20px;
                }

                .cheader div input {
                    width: 90%;
                }
            `}</style>
        </>
        );
}
