import React, { useRef, useEffect } from 'react';


function Sparkline({vals, max, height, maxWidth}) {
    const canvas = useRef();

    useEffect(() => {
        if (vals.length === 0) return;
        const el = canvas.current;
        el.width = Math.min(vals.length, maxWidth);
        el.height = height;
        let ctx = el.getContext("2d");

        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(0, 100);
        for (let i=0; i<vals.length && i<maxWidth; i++) {
            ctx.lineTo(i, height-(height*vals[i]/max));
            ctx.stroke()
        }
    }, [vals, height, max, maxWidth]);

    return <canvas ref={canvas}></canvas>;
}

export default function ResponseInfo({headers, token_timestamps, dt_start_request, dt_first_token, dt_last_token}) {
    var gaps = [];
    for (let i=0; i<token_timestamps.length-2; i++) { gaps.push(token_timestamps[i+1]-token_timestamps[i]) } 
    var intertoken = 0;
    for (let gap of gaps) { intertoken += gap }
    intertoken /= gaps.length;

    let short_endpoint = null;
    try {
        const url = new URL(headers['AMLEndpoint']);
        short_endpoint = url.hostname.replace('.inference.ml.azure.com', '');
    }
    catch (e) {}

    let short_papyrus = null;
    try {
        const url = new URL(headers['papyrus-model-endpoint']);
        short_papyrus = {
            'hostname': url.hostname.replace('.openai.azure.com', ''),
            'model': url.pathname.split('/').pop(),
            'version': url.searchParams.get('api-version')
        }
    }
    catch (e) {}

    useEffect(() => {
        if (gaps.length > 0) {
            console.log("Intertoken gaps", gaps)
        }
    }, [dt_last_token]);

    return (<table>
        <tbody>

                {headers['azureml-model-deployment'] &&
                <tr>
                    <td>Deployment</td>
                    <td>{headers['azureml-model-deployment']}</td>
                </tr>
                }

                {headers['AMLEndpoint'] &&
                <tr>
                    <td>Endpoint</td>
                    <td><span title={headers['AMLEndpoint']}>{short_endpoint || headers['AMLEndpoint']}</span></td>
                </tr>
                }

                {headers['papyrus-model-endpoint'] && !short_papyrus &&
                <tr>
                    <td>Endpoint</td>
                    <td><input value={headers['papyrus-model-endpoint']} /></td>
                </tr>}
                {short_papyrus &&
                <>
                <tr>
                    <td>Endpoint</td>
                    <td><span title={headers['papyrus-model-endpoint']}>{short_papyrus.hostname}</span></td>
                </tr>
                <tr>
                    <td>Model</td>
                    <td>{short_papyrus.model}</td>
                </tr>
                <tr>
                    <td>Version</td>
                    <td>{short_papyrus.version}</td>
                </tr>
                </>
                }

                {headers['x-ms-region'] &&
                <tr>
                    <td>Region</td>
                    <td>{headers['x-ms-region']}</td>
                </tr>}

                {dt_first_token && 
                <tr>
                    <td>TTFB</td>
                    <td>{dt_first_token - dt_start_request} ms</td>
                </tr>
                }

                {dt_last_token &&
                <tr>
                    <td>TTLB</td>
                    <td>{dt_last_token - dt_start_request} ms</td>
                </tr>}

                {token_timestamps.length > 0 &&
                <>
                <tr>
                    <td>Intertoken</td>
                    <td>{intertoken.toFixed(1)} ms
                    </td>
                </tr>
                <tr>
                    <td>Tokens/sec</td>
                    <td>{(1000/intertoken).toFixed(1)}</td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <Sparkline max="200" height="20" maxWidth="256" vals={gaps}></Sparkline>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <span title="x-ms-client-request-id">{headers['x-ms-client-request-id']}</span>
                    </td>
                </tr>

                </>
                }

        </tbody>

            </table>
    )

}