import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";
import { BrowserRouter } from 'react-router-dom';
import { PlaygroundProvider } from './PlaygroundProvider';

const msalInstance = new PublicClientApplication(msalConfig);



console.log("Arriving with URL", window.location.href);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MsalProvider instance={msalInstance}>
      <PlaygroundProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PlaygroundProvider>
    </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
