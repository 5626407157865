export function harmonyCompletions(context) {
  let word = context.matchBefore(/<*/)
  if (!word) {
    return null;
  }
  if (word.from === word.to && !context.explicit) {
    return null
  }

  return {
    from: word.from,
    options: [
      {label: "<|im_start|>system", boost: 99, detail: "Turn start"},
      {label: "<|im_start|>user", boost: 99, detail: "Turn start"},
      {label: "<|im_start|>assistant", boost: 99, detail: "Turn start"},
      {label: "<|im_sep|>", boost: 98, detail: "Turn separator"},
      {label: "<|im_end|>", detail: "Turn end"},
    ]
  }
}

export function roleCompletions(context) {
  let word = context.matchBefore(/^\[*/)
  if (!word) {
    return null;
  }
  if (word.from === word.to && !context.explicit) {
    return null
  }

  return {
    from: word.from,
    options: [
      {label: "[system](#instructions)", boost: 99, detail: "System instructions"},
      {label: "[user](#message)", boost: 99, detail: "User input"},
      {label: "[assistant](#message)", boost: 99, detail: "Response"},
      {label: "[assistant](#inner_monologue)", boost: 99, detail: "Response"},
    ]
  }
}