import { Prec, EditorSelection } from '@codemirror/state';
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import CodeMirror from '@uiw/react-codemirror';
import { defaultHighlightStyle, syntaxHighlighting, HighlightStyle } from '@codemirror/language';
import { tags } from '@lezer/highlight';
import { languages } from '@codemirror/language-data';
import { EditorView, keymap } from '@codemirror/view';
import './PlaygroundInput.css'
import {autocompletion} from "@codemirror/autocomplete"
import { useRef } from 'react'
import { usePlaygroundState } from '../PlaygroundProvider.js';

import { specialTokenHighlighter, fenceHighlighter, fieldHighlighter, jinjaHighlighter, personaHighlighter } from './Highlighters';
import { harmonyCompletions, roleCompletions } from './Completions';
import { GetLogprobsTable, logprobsEffect, logprobsField, logprobsFieldTooltip, logprobsTheme } from './Logprobs.ts';
import { generatedField, generatedEffect, generatedTheme } from './Generated.ts';



export default function PlaygroundInput({value, isStreaming, onExecute, onChange}) {
  const ref = useRef(null);
  const [, dispatch] = usePlaygroundState();

    return (<CodeMirror
        value={value}
        onChange={onChange}
        onCreateEditor={editor => { editor.focus(); dispatch({type: 'setEditorView', value: editor})}}
        ref={ref}

        extensions={[
            Prec.highest(keymap.of([
                {
                    key: 'Ctrl-Enter', 
                    preventDefault: true, 
                    run: cm=>{ onExecute(); return true; } 
                },
              ])),
            markdown({ base: markdownLanguage, codeLanguages: languages }), 
            markdownLanguage.data.of({closeBrackets: {brackets: []}}),
            specialTokenHighlighter,
            fenceHighlighter,
            fieldHighlighter,
            personaHighlighter,
            jinjaHighlighter,
            EditorView.lineWrapping,
            autocompletion({override: [harmonyCompletions, roleCompletions]}),
            syntaxHighlighting(defaultHighlightStyle),
            syntaxHighlighting(highlighter),
            [logprobsField, logprobsFieldTooltip, logprobsTheme],
            [generatedField, generatedTheme],
        ]} 
        ></CodeMirror>)
}



const highlighter = HighlightStyle.define([
  {tag: tags.heading1, color: '#000080', fontWeight: 'bold', fontSize: '1.75em'},
  {tag: tags.heading2, color: '#000080', fontWeight: 'bold', fontSize: '1.5em'},
  {tag: tags.monospace, backgroundColor: '#dedede', fontFamily: 'Consolas'},
]);



export function HandleAppend(view, value, logprobs) {
  if (value === null || value.length === 0) return;

  let doc = view.state.doc;
  view.dispatch({
      changes: {from: doc ? doc.length : 0, insert: value},
      selection: EditorSelection.single(doc.length + value.length),
      scrollIntoView: true,
      effects: [
        generatedEffect.of({from: doc.length, to:doc.length + value.length}),
      ]
  });

  if (logprobs) {
    view.dispatch({
      effects: [
        logprobsEffect.of({from: doc.length, to:doc.length + value.length, logprobs: GetLogprobsTable(logprobs)}),
      ]
    })
  }

}